<template>
    <div class="w-100 user-select-none">

        <!--desktop-->
        <ul v-if="!mobile"
            class="navbar-nav justify-content-center flex-grow-1 flex-wrap px-3 gap-3 list-unstyled">
            <template v-for="link in links">
                <li v-if="canAccessLink(link)">
                    <div class="nav-item">
                        <nuxt-link
                            :to="link.path"
                            class="nav-link py-0 text-uppercase">
                            {{ link.name }}
                        </nuxt-link>
                    </div>
                </li>
            </template>

            <li class="nav-item dropdown dropdown-center" v-if="$can('viewAny', 'users')">
                <a class="nav-link py-0 text-uppercase"
                   href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ $t('nav.organisationGroupTitle') }}
                </a>
                <DropdownLinks :links="organisationLinks"/>
            </li>

            <li class="nav-item dropdown dropdown-center" v-if="$can('admin','system')">
                <a class="nav-link py-0 text-uppercase"
                   href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ $t('nav.systemGroupTitle') }}
                </a>
                <DropdownLinks :links="systemLinks"/>
            </li>
        </ul>

        <!--mobile-->
        <div v-else
             class="navbar-nav flex-row list-unstyled">
            <div class="d-flex justify-content-evenly flex-grow-1 align-items-center">
                <div
                    v-for="link in links.filter(link => link.position === 'left')"
                    class="mobile-nav-item"
                    :class="{'d-none' : !canAccessLink(link)}">
                    <nuxt-link
                        :to="link.path" class="mobile-nav-link">
                        <font-awesome-icon :icon="link.icon" size="lg" class="p-2"/>
                    </nuxt-link>
                </div>
                <!-- Placeholder -->
                <div v-if="mobile && links.filter(link => link.position === 'left').length < 2">
                    <div style="width: 20px; height: 20px" class="m-2"></div>
                </div>
            </div>

            <div class="mobile-nav-item flex-shrink-1"
                 v-for="link in links.filter(link => link.position === 'center')">
                <nuxt-link :to="link.path" class="mobile-buzzer-link d-flex position-relative">
                    <div
                        class="position-absolute bg-primary top-0 rounded-circle d-flex align-items-center justify-content-center">
                        <svg height="55%" width="55%" viewBox="0 0 353 419" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M139.636 27.178V370.066L18.9905 238.376V71.3581L139.636 27.178ZM327.887 240.48L207.779 370.393V27.178L328.424 71.3581V123.87L290.324 169.991L280.423 181.977L290.218 194.05L327.887 240.48Z"
                                fill="transparent" stroke="#000" stroke-width="37.981" class="p-2"/>
                        </svg>
                    </div>
                </nuxt-link>
            </div>

            <div class="d-flex justify-content-evenly flex-grow-1 align-items-center">
                <div v-for="link in links.filter(link => link.position === 'right')"
                     class="mobile-nav-item"
                     :class="{'d-none' : !canAccessLink(link)}">
                    <nuxt-link
                        :to="link.path" class="mobile-nav-link">
                        <font-awesome-icon :icon="link.icon" size="lg" class="p-2"/>
                    </nuxt-link>
                </div>

                <ul class="mb-0 p-0">
                    <li class="mobile-nav-item list-unstyled">
                        <MobileSubmenu
                            :orgLinks="organisationLinks"
                            :sysLinks="systemLinks"
                            :infoLinks="infoLinks"/>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup>
import DropdownLinks from "@/components/layouts/DropdownLinks";
import MobileSubmenu from "@/components/layouts/MobileSubmenu";
import {useAbility} from "@casl/vue";
import {useAuthStore} from "~/store/auth";

const {t} = useI18n()
const config = useRuntimeConfig()
const { can } = useAbility()

const props = defineProps({
    mobile: false
})

const authStore = useAuthStore()

const links = computed(() => [
    {
        name: t('nav.home'),
        path: '/',
        icon: 'house',
        position: 'left',
    },
    {
        name: t('nav.events'),
        path: '/events',
        icon: 'calendar-alt',
        position: 'left',
    },
    {
        name: t('nav.dashboards'),
        path: '/dashboards',
        icon: 'table-columns',
        conditionSubject: 'dashboards',
        conditionAction: 'viewAny',
        enabled: config.public.features.dashboardsEnabled || can('can_use_dashboards', 'pre_release_features'),
        position: 'left',
    },
    {
        name: t('nav.buzzer'),
        path: '/buzzer',
        position: 'center',
    },
    {
        name: t('nav.audios'),
        path: '/sounds/entries',
        icon: 'music',
        conditionSubject: 'sounds',
        conditionAction: 'create',
        position: 'right',
    },
]);

const organisationLinks = computed(() => [
    {
        name: t('nav.events'),
        path: 'organisation-deals',
    },
    {
      name: t('nav.eventTypes'),
      path: 'organisation-eventTypes',
    },
    {
        name: t('nav.devices'),
        path: 'organisation-devices',
    },
    {
        name: t('nav.goals'),
        path: 'organisation-goals',
    },
    {
        name: t('nav.organisation'),
        path: 'organisation-users',
    },
    {
        name: t('nav.subscriptions'),
        path: 'organisation-subscriptions',
    },
    {
        name: t('nav.organisationSettings'),
        path: 'organisation-settings',
    },
]);

const systemLinks = computed(() => [
    {
        name: t('nav.globalUsers'),
        path: 'system-users',
    },
    {
        name: t('nav.globalOrganisations'),
        path: 'system-organisations',
    },
    {
        name: t('nav.globalDevices'),
        path: 'system-devices',
    },
    {
        name: t('nav.logfiles'),
        path: 'system-logs',
    },
    /*{
        //TODO
        name: 'Globale Statistik',
        path: '',
    },*/
    /*{
        name: 'Test',
        path: 'system-test',
    },*/
    {
        name: t('nav.apiDocs'),
        url: `${config.public.serverRoute}/app/docs`,
    },
    {
        name: t('nav.firmware'),
        path: 'system-firmware',
    },
    {
        name: t('nav.hardware'),
        path: 'system-hardware'
    },
    {
        name: t('nav.plans'),
        path: 'system-plans'
    },
    {
        name: t('nav.notificationTemplates'),
        path: 'system-notificationTemplates'
    },
    {
        name: t('nav.tools'),
        path: 'system-tools'
    }
]);

const infoLinks = computed(() => [
    {
        name: t('nav.privacy.name'),
        url: t('nav.privacy.url')
    },
    {
        name: t('nav.imprint.name'),
        url: t('nav.imprint.url')
    },
    {
        name: t('nav.termsConditions.name'),
        url: t('nav.termsConditions.url')
    }
]);

function canAccessLink(link) {
    if (Object.hasOwn(link, 'enabled')) {
        if (!link.enabled) return false
    }
    return can(link.conditionAction, link.conditionSubject) || (!link.conditionSubject && !link.conditionAction)
}
</script>